
.popup-area-container[data-v-02de39bc]{
    background: #ffffff;
    overflow: hidden;
    padding-bottom: 20px;
}
.popup-area-header[data-v-02de39bc]{
    height: 40px;
    line-height: 40px;
    padding:0 10px;
}
.popup-area-header .fr[data-v-02de39bc]{
    color: rgba(7,193,96,1);
}
.popup-area[data-v-02de39bc]{
    display: inline-grid;
    padding: 5px ;
    grid-template-columns: repeat(4, 24%);
    width: 100%;
}
.popup-area li[data-v-02de39bc]{
    display: inline-block;
    border:1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 3px;
}
.popup-area li.selected[data-v-02de39bc]{
    border:1px solid rgba(7,193,96,1);
    color: #07c160;
    background: rgba(7,193,96,.3);
}
.area-loading[data-v-02de39bc]{
    padding-top: 50px;
    min-height: 200px;
    text-align: center;
}
