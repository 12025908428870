:root {
  --styleColor: #dd7e6b;
}
.body {
  padding: 16px;
  background-color: #f8f8f8;
  color: var(--styleColor);
  min-height: 100vh;
  font-size: 15px;
  margin: 0;
  box-sizing: border-box;
}
.header_box,
.signal-top {
  display: flex;
  align-items: center;
  font-size: 17px;
  margin-bottom: 20px;
}
.header_box .logo,
.signal-top .logo {
  height: 100px;
  width: 100px;
  margin-right: 15px;
  overflow: hidden;
}
.logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.signal-btn {
  text-align: right;
  flex: 1;
}
.signal-btn .follow {
  font-size: 14px;
  padding: 3px 10px;
}
.signal-list {
  color: #323233;
  font-size: 14px;
  line-height: 14px;
}
.signal-row {
  border-bottom: 1px solid #ebedf0;
  padding: 10px 16px;
  background: #fff;
}
.load-more {
  color: #969799;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
}
.footer {
  display: none;
}
.float_box {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background: var(--styleColor);
  position: fixed;
  bottom: 40px;
  right: 15px;
  color: #fff;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.float_box .border_box {
  width: 66px;
  height: 66px;
  border-radius: 66px;
  border: 1px solid #fff;
}
.float_box .border_box .text {
  width: 35px;
  text-align: center;
}
.float_box .border_box .text a {
  color: #fff;
}
.button a {
  display: block;
  width: 100%;
  text-align: center;
  height: 45px;
  line-height: 45px;
}
button.layui-btn {
  background-color: var(--styleColor);
  display: block;
  margin-top: 15px;
  overflow: visible;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  border-color: var(--styleColor);
  width: 100%;
}
.text-center {
  text-align: center;
}
/***留言**/
.msg-user-info {
  padding-top: 8px;
}
.msg-user-info .user-avatar {
  float: left;
}
.msg-user-info .user-text {
  display: inline-block;
}
.msg-user-info .fav-btn {
  float: right;
}
.msg-user-info .user-text p {
  padding: 0;
  margin: 0;
}
.msg-user-info .user-avatar img {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}
.msg-user-info span {
  vertical-align: top;
  margin: 10px 0 0 8px;
}
.msg-content {
  line-height: 1.5;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.msg-content .view-more {
  color: #969799;
  font-size: 14px;
}
.post-time {
  flex: 1;
  color: #969799;
}
.msg-opt {
  text-align: right;
}
.msg-opt a {
  font-size: 14px;
  color: #969799;
}
.text-avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background: #dd7e6b;
  color: #ffffff;
  font-size: 14px;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2px 5px 3px 0;
}
.aName {
  font-size: 14px;
  font-weight: 600;
}
.aTime {
  font-size: 14px;
  color: #999;
}
.aCont {
  font-size: 16px;
  line-height: 1.5;
  padding-top: 8px;
}
.f18 {
  font-size: 18px;
}
body {
  background: #f5f5f5;
}
#app,
.layout {
  margin: 0 auto;
  max-width: 640px;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: #fff;
}
.loading-wrapper {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.van-overlay {
  background: rgba(255, 255, 255, 0.5) !important;
}
.filter-box {
  border-bottom: 1px #f2f2f2 solid;
}
.van-cell {
  padding: 10px;
}
.van-cell__title a {
  color: #333;
}
