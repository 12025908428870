.container {
    margin: 0 20px;
}
.clearfix {
    zoom: 1;
}
.clearfix:before,
.clearfix:after {
    clear: both;
    content: '\0020';
    display: block;
    height: 0;
    overflow: hidden;
}
.main-panel {
    background: #ffffff;
    border-radius: 0.16rem;
}
.card-content {
    margin-bottom: 24px;
    padding: 24px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 12px #ebedf0;
}
.card-content .label-text {
    color: #646566;
    text-align: left;
    word-wrap: break-word;
    font-size: 14px !important;
}
.image-container {
    padding: 16px;
    background: #ffffff;
}
.image-container img {
    width: 100%;
}
.bottom-blank {
    height: 60px;
}
.middle-title .van-cell__title {
    font-size: 16px;
}
.big-title .van-cell__title {
    font-size: 20px;
}
.font-bold {
    font-weight: bold !important;
}
.yellow-quote {
    background-color: rgba(255, 229, 100, 0.3);
    border-color: rgba(255, 229, 100, 1);
    color: #6b5900;
    padding: 0.5rem 0;
    border-left-width: 0.2rem;
    border-left-style: solid;
    margin: 1rem 0;
}
blockquote {
    background-color: rgba(255, 229, 100, 0.3);
    border-color: #e7c000;
    color: #6b5900;
    padding: 0.5rem 0;
    border-left-width: 0.2rem;
    border-left-style: solid;
    margin: 1rem 0;
}
.green-quote {
    background-color: #f3f5f7;
    border-color: #42b983;
    padding: 0.5rem 0;
    border-left-width: 0.2rem;
    border-left-style: solid;
    margin: 1rem 0;
}

/******my******/
.text-desc {
    color: rgba(69, 90, 100, 0.6);
    font-size: 14px;
}
.text-dander {
    color: #ee0a24 !important;
}
.text-success {
    color: #07c160 !important;
}
.text-primary {
    color: #1989fa !important;
}
.text-warning {
    color: #ff976a !important;
}
.text-center {
    text-align: center !important;
}
.fr {
    float: right;
}
.fl {
    float: left;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
/*****card***/
.van__card {
    margin: 12px 12px 0;
    overflow: hidden;
    border-radius: 8px;
}
.van__card__title {
    margin: 0;
    padding: 32px 16px 16px;
    color: rgba(69, 90, 100, 0.6);
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
}
.van__card .van-form {
    border-radius: 8px;
}
.van__card .van-field:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.van__card .van-cell:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.item-card {
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
    margin: 0 16px;
    color: #323233;
    font-size: 14px;
    box-shadow: 0 8px 12px #ebedf0;
    padding: 10px;
}
.item-card-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #ebedf0;
}
.item-card-title .van-cell {
    padding: 0 !important;
}
.item-card-content {
}
code,
kbd,
pre,
samp,
mark,
var,
abbr,
cite,
ins {
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/**红色**/
code {
    background-color: #e7e3f2;
    color: red;
    padding: 2px 7px;
    border-radius: 2px;
    font-size: 97%;
}
/**淡蓝色**/
mark {
    background-color: #f2f6fc;
    color: #303133;
    opacity: 0.85;
    padding: 2px 7px;
    border-radius: 2px;
    font-size: 97%;
}
/**淡黄色*/
samp {
    background-color: rgba(255, 229, 100, 0.3);
    color: #6b5900;
    opacity: 0.85;
    padding: 2px 7px;
    border-radius: 2px;
    font-size: 97%;
}
/***黑色***/
ins {
    background-color: rgba(30, 30, 30, 1);
    color: #ffffff;
    padding: 2px 7px;
    border-radius: 2px;
    font-size: 97%;
    text-decoration: none !important;
}
/***橘色***/
cite {
    background-color: rgba(255, 159, 67, 0.3);
    color: #9a5d23;
    font-style: normal;
    padding: 2px 7px;
    border-radius: 2px;
    font-size: 97%;
}
/**绿色**/
abbr {
    background-color: rgba(40, 199, 111, 0.3);
    color: #056a32;
    padding: 2px 7px;
    border-radius: 2px;
    font-size: 97%;
}
/**紫色**/
var {
    background-color: rgba(115, 103, 240, 0.3);
    color: #453e90;
    font-style: normal;
    padding: 2px 7px;
    border-radius: 2px;
    font-size: 97%;
}
.mescroll {
    position: fixed;
    top: 160px;
    bottom: 70px;
    height: auto; /*如设置bottom:50px,则需height:auto才能生效*/
}
/*-------------------------------------------------------------------*/
/* === padding margin fixed === */
/*-------------------------------------------------------------------*/
.h100per {
    height: 100% !important;
}
.no-bg {
    background: transparent !important;
}
.mar-no {
    margin: 0 !important;
}
.mar-all {
    margin: 16px !important;
}
.mar-all-large {
    margin: 20px !important;
}
.mar-all-more {
    margin: 30px !important;
}
.mar-top {
    margin-top: 16px !important;
}
.mar-top-large {
    margin-top: 20px !important;
}
.mar-top-more {
    margin-top: 30px !important;
}
.mar-top-small {
    margin-top: 10px !important;
}
.mar-top-mini {
    margin-top: 5px !important;
}
.mar-top-no {
    margin-top: 0 !important;
}
.mar-btm {
    margin-bottom: 16px !important;
}
.mar-btm-large {
    margin-bottom: 20px !important;
}
.mar-btm-more {
    margin-bottom: 30px !important;
}
.mar-btm-small {
    margin-bottom: 10px !important;
}
.mar-btm-mini {
    margin-bottom: 5px !important;
}
.mar-btm-no {
    margin-bottom: 0 !important;
}
.mar-lft {
    margin-left: 16px !important;
}
.mar-lft-large {
    margin-left: 20px !important;
}
.mar-lft-more {
    margin-left: 30px !important;
}
.mar-lft-small {
    margin-left: 10px !important;
}
.mar-lft-mini {
    margin-left: 5px !important;
}
.mar-lft-no {
    margin-left: 0 !important;
}
.mar-rgt {
    margin-right: 16px !important;
}
.mar-rgt-large {
    margin-right: 20px !important;
}
.mar-rgt-more {
    margin-right: 30px !important;
}
.mar-rgt-small {
    margin-right: 10px !important;
}
.mar-rgt-mini {
    margin-right: 5px !important;
}
.mar-rgt-no {
    margin-right: 0 !important;
}
.mar-hor {
    margin-left: 16px;
    margin-right: 16px;
}
.mar-hor-large {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.mar-hor-more {
    margin-left: 30px !important;
    margin-right: 30px !important;
}
.mar-hor-small {
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.mar-hor-mini {
    margin-left: 5px !important;
    margin-right: 5px !important;
}
.mar-hor-no {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.mar-ver {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}
.mar-ver-large {
    margin-top: 20px;
    margin-bottom: 20px;
}
.mar-ver-more {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.mar-ver-small {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.mar-ver-mini {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.mar-ver-no {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.pad-no {
    padding: 0 !important;
}
.pad-all {
    padding: 16px !important;
}
.pad-all-large {
    padding: 20px !important;
}
.pad-all-more {
    padding: 30px !important;
}
.pad-all-small {
    padding: 10px !important;
}
.pad-all-mini {
    padding: 5px !important;
}
.pad-top {
    padding-top: 16px !important;
}
.pad-top-large {
    padding-top: 20px !important;
}
.pad-top-no {
    padding-top: 0 !important;
}
.pad-top-more {
    padding-top: 30px !important;
}
.pad-top-small {
    padding-top: 10px !important;
}
.pad-top-mini {
    padding-top: 5px !important;
}
.pad-btm {
    padding-bottom: 16px !important;
}
.pad-btm-large {
    padding-bottom: 20px !important;
}
.pad-btm-no {
    padding-bottom: 0 !important;
}
.pad-btm-more {
    padding-bottom: 30px !important;
}
.pad-btm-small {
    padding-bottom: 10px !important;
}
.pad-btm-mini {
    padding-bottom: 5px !important;
}
.pad-lft {
    padding-left: 16px !important;
}
.pad-lft-large {
    padding-left: 20px !important;
}
.pad-lft-no {
    padding-left: 0 !important;
}
.pad-lft-more {
    padding-left: 30px !important;
}
.pad-lft-small {
    padding-left: 10px !important;
}
.pad-lft-mini {
    padding-left: 5px !important;
}
.pad-rgt {
    padding-right: 16px !important;
}
.pad-rgt-large {
    padding-right: 20px !important;
}
.pad-rgt-no {
    padding-right: 0 !important;
}
.pad-rgt-more {
    padding-right: 30px !important;
}
.pad-rgt-small {
    padding-right: 10px !important;
}
.pad-rgt-mini {
    padding-right: 5px !important;
}
.pad-hor {
    padding-left: 16px;
    padding-right: 16px;
}
.pad-hor-large {
    padding-left: 16px;
    padding-right: 16px;
}
.pad-hor-no {
    padding-left: 0;
    padding-right: 0;
}
.pad-hor-more {
    padding-left: 30px;
    padding-right: 30px;
}
.pad-hor-small {
    padding-left: 10px;
    padding-right: 10px;
}
.pad-hor-mini {
    padding-left: 5px;
    padding-right: 5px;
}
.pad-ver {
    padding-top: 16px;
    padding-bottom: 16px;
}
.pad-ver-large {
    padding-top: 20px;
    padding-bottom: 20px;
}
.pad-ver-no {
    padding-top: 0;
    padding-bottom: 0;
}
.pad-ver-more {
    padding-top: 30px;
    padding-bottom: 30px;
}
.pad-ver-small {
    padding-top: 10px;
    padding-bottom: 10px;
}
.pad-ver-mini {
    padding-top: 5px;
    padding-bottom: 5px;
}
.filter-box {
    display: flex;
    height: 48px;
    background-color: #fff;
    box-shadow: 0 2px 12px rgb(100 101 102 / 12%);
    line-height: 48px;
}
.filter-item {
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.load-more {
    color: #969799;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
}
.lst-ban img {
    max-width: 100%;
}